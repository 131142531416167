import { useQuery } from "react-query";
import {
  adminDetails,
  contactUsData,
  contactUsDetails,
  referralData,
  referralDetails,
} from "../Services";

export const useAdminDetails = () => {
  return useQuery(["adminDetails"], () => adminDetails());
};

export const useContactUs = (params) => {
  return useQuery(["contactUsList", { ...params }], () =>
    contactUsData(params)
  );
};

export const useContactUsDetails = (id) => {
  return useQuery(["contactUsDetails", { id }], () => contactUsDetails(id));
};

export const useReferences = (params) => {
  return useQuery(["referralData", { ...params }], () => referralData(params));
};

export const useReferenceDetails = (id) => {
  return useQuery(["referralDetails", { id }], () => referralDetails(id));
};
