import React from "react";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useAdminDetails } from "../../Hooks/Query";

const Navbar = () => {
  const { data } = useAdminDetails();
  const name = get(data, "data.data.data.name");

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    toast.success("Logout Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };
  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>

        <ul className="navbar-nav navbar-nav-right">
          <div className="navbar-profile">
            <p className="mb-0 d-none d-sm-block navbar-profile-name">
              {name ? ` ${name}` : `Admin`}
              <span
                className="mdi mdi-logout ml-2"
                onClick={handleLogout}
                style={{ cursor: "pointer", color: "blue" }}
              ></span>
            </p>
          </div>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-format-line-spacing"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
