import React from "react";
import { Spinner } from "react-bootstrap";

const SpinnerComponent = () => {
  return (
    <div>
      <div className="spinner-wrapper">
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" variant="info" />
        </div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
