import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const newProps = { ...props, location, navigate, params };
    return <Component {...newProps} />;
  }

  return ComponentWithRouterProp;
}
