import React, { Suspense, lazy } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import SpinnerComponent from "./Pages/Shared/Spinner";

const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
// cms pages
const Home = lazy(() => import("./Pages/CMS/Home/index"));
const About = lazy(() => import("./Pages/CMS/About/index"));
const Services = lazy(() => import("./Pages/CMS/Services/index"));
const Projects = lazy(() => import("./Pages/CMS/Projects/index"));
const Careers = lazy(() => import("./Pages/CMS/Careers/index"));
const ContactUS = lazy(() => import("./Pages/CMS/ContactUs/index"));
// const Layout = lazy(() => import("./Pages/CMS/Layout/index"));
const ContactUsTable = lazy(() => import("./Pages/ContactUs"));
const ReferenceDetails = lazy(() => import("./Pages/Reference"));
const ReferenceUser = lazy(() => import("./Pages/Reference/Details"));
const AdminInfo = lazy(() => import("./Pages/AdminProfile/index"));
const ContactUser = lazy(() => import("./Pages/ContactUs/Details"));
const Header = lazy(() => import("./Pages/CMS/Header"));
const Footer = lazy(() => import("./Pages/CMS/Footer"));

// errorpage
const Error404 = lazy(() => import("./Pages/Error-pages/Error404"));
const Error500 = lazy(() => import("./Pages/Error-pages/Error500"));
// login/signup
const Login = lazy(() => import("./Pages/Auth/Login"));

export const LoginRoute = () => {
  return (
    <Suspense fallback={<SpinnerComponent />}>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </Suspense>
  );
};

const AppRoutes = () => {
  return (
    <Suspense fallback={<SpinnerComponent />}>
      <Routes>
        <Route path="/cms/home" element={<Home />} />
        <Route path="/cms/about" element={<About />} />
        <Route path="/cms/services" element={<Services />} />
        <Route path="/cms/projects" element={<Projects />} />
        <Route path="/cms/careers" element={<Careers />} />
        <Route path="/cms/contact" element={<ContactUS />} />
        {/*<Route path="/cms/layout" element={<Layout />} />*/}
        <Route path="/cms/header" element={<Header />} />
        <Route path="/cms/footer" element={<Footer />} />
        <Route path="/settings/admin" element={<AdminInfo />} />

        <Route path="/contact-us" element={<ContactUsTable />} />
        <Route path="/contact-details/:id" element={<ContactUser />} />
        <Route path="/references" element={<ReferenceDetails />} />
        <Route path="/references-details/:id" element={<ReferenceUser />} />

        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/error-pages/error-404" element={<Error404 />} />
        <Route path="/error-pages/error-505" element={<Error500 />} />

        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
