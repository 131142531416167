import AppRoutes from "../AppRoutes";
import React from "react";
import { Navigate } from "react-router-dom";

const logAuth = () => {
  const isChecked = localStorage.getItem("authToken");
  const loginFn = { isLoggedIn: isChecked ? true : false };
  return loginFn.isLoggedIn;
};

const ProctectedRoutes = () => {
  const isAuth = logAuth();
  const userRedirect = {
    loggedIn: isAuth ? <AppRoutes /> : <Navigate to="/" />,
  };
  return userRedirect.loggedIn;
};

export default ProctectedRoutes;
