import ApiCaller from "../Utils/api";

export const login = (data) => {
  return ApiCaller("admin/login", "POST", data, process.env.REACT_APP_BASE_API);
};

export const changePassword = (data) => {
  return ApiCaller(
    "admin/change-password",
    "POST",
    data,
    process.env.REACT_APP_BASE_API
  );
};

export const updateAdminInfo = (data) => {
  return ApiCaller("admin", "PUT", data, process.env.REACT_APP_BASE_API);
};

export const adminDetails = () => {
  return ApiCaller("admin/details", "GET", {}, process.env.REACT_APP_BASE_API);
};

export const HomePageData = () => {
  return ApiCaller("home/details", "GET", {}, process.env.REACT_APP_BASE_API);
};

export const AboutPageData = () => {
  return ApiCaller(
    "about-us/details",
    "GET",
    {},
    process.env.REACT_APP_BASE_API
  );
};

export const ServicesPageData = async () => {
  return await ApiCaller(
    "service/details",
    "GET",
    {},
    process.env.REACT_APP_BASE_API
  );
};

export const ProjectPageData = () => {
  return ApiCaller(
    "project/details",
    "GET",
    {},
    process.env.REACT_APP_BASE_API
  );
};
export const ContactUsPageData = () => {
  return ApiCaller(
    "contact-us/details",
    "GET",
    {},
    process.env.REACT_APP_BASE_API
  );
};

export const CareerPageData = () => {
  return ApiCaller("career/details", "GET", {}, process.env.REACT_APP_BASE_API);
};

export const referralData = (filter) => {
  return ApiCaller(
    `referral?page=${filter.page}&limit=${filter.limit}&search=${filter.search}`,
    "GET",
    {},
    process.env.REACT_APP_BASE_API
  );
};

export const referralDetails = (id) => {
  return ApiCaller(`referral/${id}`, "GET", {}, process.env.REACT_APP_BASE_API);
};

export const contactUsData = (filter) => {
  return ApiCaller(
    `contact?page=${filter.page}&limit=${filter.limit}&search=${filter.search}`,
    "GET",
    {},
    process.env.REACT_APP_BASE_API
  );
};
export const HeaderData = () => {
  return ApiCaller("header/details", "GET", {}, process.env.REACT_APP_BASE_API);
};

export const contactUsDetails = (id) => {
  return ApiCaller(`contact/${id}`, "GET", {}, process.env.REACT_APP_BASE_API);
};

export const updateReferralStatus = (prams) => {
  return ApiCaller(
    `referral/${prams.id}`,
    "PUT",
    prams.data,
    process.env.REACT_APP_BASE_API
  );
};

//POST API's

export const HomepagePost = (data) => {
  return ApiCaller("home", "POST", data, process.env.REACT_APP_BASE_API);
};

export const AboutpagePost = (data) => {
  return ApiCaller("about-us", "POST", data, process.env.REACT_APP_BASE_API);
};
export const UpdateServices = (data) => {
  return ApiCaller("service", "POST", data, process.env.REACT_APP_BASE_API);
};

export const ContactUsPost = (data) => {
  return ApiCaller("contact-us", "POST", data, process.env.REACT_APP_BASE_API);
};

export const UpdateCareer = (data) => {
  return ApiCaller("career", "POST", data, process.env.REACT_APP_BASE_API);
};

export const HeaderPost = (data) => {
  return ApiCaller("header", "POST", data, process.env.REACT_APP_BASE_API);
};

export const UpdateFooter = (data) => {
  return ApiCaller("footer", "Post", data, process.env.REACT_APP_BASE_API);
};
